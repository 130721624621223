<template>
  <section id="content">
    <base-card>
      <v-layout
        justify-start
        mb-4
      >
        <v-btn
          to="/projects"
          class="font-weight-light ma-0"
          color="accent"
        >
          <v-icon>mdi-arrow-left</v-icon> Back
        </v-btn>
      </v-layout>
      <post :value="post" />

      <!--      <comment />-->
    </base-card>
  </section>
</template>

<script>
  // Utilities
  import {
    mapState
  } from 'vuex'

  export default {
    components: {
      // Comment: () => import('@/components/posts/Comment'),
      Post: () => import('@/components/Post')
    },

    computed: {
      ...mapState('content', ['posts']),
      post () {
        return this.posts.find(p => p.slug === this.$route.params.slug)
      }
    }
  }
</script>
